.footer{
    bottom: 0%;
    width: 100%;
    background-color: #000000;
    justify-content: center;
    text-align: center;
    color: whitesmoke;
}

.footer-logo{
    align-self: center;
    padding-top: 1%;
    padding-bottom: 10px;
}

.logo-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
}

.sm-link{
    width: 2%;
    margin-right: 5px;
}

@media (max-width: 760px) {
    .footer-logo img{
        padding-top: 5%;
        width: 40%;
    }

    .social-media-links img{
        width: 10%;
    }

    .copyright{
        font-size: 8px;
    }
}

