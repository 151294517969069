
.ui.menu {
    background: #000000;
    z-index: 1030;
    position: fixed;
    font-size: large;
  }

  .nav {
    height: fit-content;
    font-family: 'Arial Narrow';
  }
  
  #home, #socialmedia, #webdesign, #videophoto, #branding, #contact{
    background-color: #000000;
    color: whitesmoke;
    transition: 2s;
    font-size: larger;
    padding: 0%;
    letter-spacing: 0;
    font-family: 'Arial Narrow';
  }
  
  #home:hover, #socialmedia:hover, #webdesign:hover, #videophoto:hover, #branding:hover, #contact:hover{
    background-color: whitesmoke;
    color: #000000;
    cursor: pointer;
    font-weight: bold;
  }

  @media (max-width: 500px) {
    .ui.menu{
      font-size: 9px;
      height: fit-content;
      padding-top: 10%;
    }
  }
  
  