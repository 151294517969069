@font-face {
    font-family: Brixton_Wood;
    src: url('../images/Brixton_Wood_PERSONAL_USE_ONLY-Vector.otf') format('opentype');
}

.videophoto-div{
    color: whitesmoke;
    background-color: black;
    font-family: 'Brixton_Wood';
    padding-top: 2%;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
    height: fit-content;
    border-bottom: 1px dotted white;
}

.videophoto-header{
    width: 100%;
    text-align: center;
    padding: 5% 0%;
    font-size: 150px;
    background: url('../images/Header\ banner.jpg');
    background-size: 100%;
}

.examples-container{
    background-color: black;
    padding: 5% 10%;
}

.video-examples{
    width: 100%;
   height: 40vh;
   font-size: x-large;
}

.video-examples h2 {
    font-size: 48px;
    font-family: 'Brixton_Wood';
    padding-bottom: 1%;
}

.photo-examples{
    display: flexbox;
    font-family: 'Brixton_Wood';
}

.video-card{
    width: 25%;
    float: left;
    height: fit-content;
    text-align: start;
}

.video-card p{
    padding: 0;
    height: fit-content;
}

.video-card iframe{
    position: absolute;
    width: 20%;
    height: 20%;
    padding: 1%;
    justify-content: center;
}

.photo-examples h4 {
    font-size: 48px;
    font-family: 'Brixton_Wood';
    padding-bottom: 2%;
}

img.photo{
    max-width: 70%;
}

.videophoto-footer{
    padding-bottom: 2%;
    border-bottom: 1px solid white;
}

.videophoto-footer h2{
    font-family: 'Brixton_Wood';
    font-size: 60px;
}

.learnmore{
    text-align: center;
    font-family: 'Arial Narrow';
    background-color: whitesmoke;
    color: black;
    width: 30%;
    margin: 1% 35% 1% 35%;
    font-size: xx-large;
}

.photos {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    padding: 1% 20% 1% 20%;
}

.card2{
    max-width: 300px;
    max-height: 300px;
    color: rgb(0, 0, 0);
    cursor: pointer;
    transition: 1s ease-in-out;
    transform-style: preserve-3d;

    &:hover {
      transform: rotateY(0.5turn);
    }

    .card__face {
    position: relative;
      backface-visibility: hidden;
      transition: 1s ease-in-out;
      width: 100%;
      height: 100%;
      

      &.card__face--front{
        max-height: 200px;
        display: block;
        position: relative;
        z-index: 5;
      }

      &.card__face--back {
        top: -100px;
        position: relative;
        z-index: 3;
        transform: rotateY(0.5turn);
        background-color: whitesmoke;
        color: #000000;
        font-size: large;
      }
    }
  }

.card2 img{
    padding: 0 10%;
}

.card2 p{
    font-size: xx-large;
    background-color: black;
    color: whitesmoke;
}


@media (max-width: 760px) {
    .videophoto-header{
        padding-top: 15%;
        font-size: 48px;
    }

    .video-examples{
        font-size: 12px;
        letter-spacing: 1px;
    }

    .video-examples h2{
        font-size: 24px;
    }

    .video-card{
        width: 100%;
        padding: 0 10%;
        font-size: 16px;
        letter-spacing: 2px;
    }

    .video-card iframe{
        min-width: 100%;
        position: relative;
    }

    .photo-examples h4{
        font-size: 24px;
    }

    .photos{
        display: block;
        padding: 0;
        
    }

    .card2{
        min-width: 100%;
        padding-bottom: 10%;
        margin-bottom: 0;
    }

    .card2:hover{
        backface-visibility: hidden;
    }

    .vp-foot h2{
        padding-top: 0px;
        margin: 0px;
    }

    .card__face--back{
        
        
    }

}