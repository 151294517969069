.socialmedia-div{
    background-color: black;
    color: whitesmoke;
    text-align: center;
    font-family: 'Brixton_Wood';
    border-bottom: 1px solid white;
}

::-webkit-scrollbar{
    width: 0px;
}

.socialmedia-body{
    background-color: black;
    color: whitesmoke;
    padding: 2% 5%;
}

.smlogos{
    max-height: 10%;
}

.smlogos img {
    max-width: 5%;
    padding: 0% 1%;
    justify-content: space-around;
}

.sm-bulk{
    padding: 2% 0;
    font-size: xx-large;
    
    display: flex;
}

.sm-img{
    float: left;
    width: 50%;
   
}

.sm-img p{
    
    position: relative;
    bottom: -500px;
}

.sm-img video{
    position: absolute;
    right: 50%;
    max-width: 45%;
    float: left;
    z-index: 0;
}

.sm-text{
    width: 50%;
    font-size: 48px;
    text-align: left;
    
}

.sm-footer{
    background-color: black;
    padding: 5% 0;

}

.sm-footer h2{
    font-family: 'Brixton_Wood';
    font-size: 48px;
}

@media (max-width: 700px) {
    .smlogos img{
        min-width: 10%;
        position: relative;
    }

    .sm-bulk{
        display: block;
        min-height: fit-content;
    }

    .sm-text{
        text-align: center;
        width: 95%;
        font-size: 24px;
    }

    .sm-img{
        position: relative;
        width: 100%;
        font-size: 12px;
        min-height: 40vh;
        
    }

    .sm-img video{
        min-width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        padding-top: 15%;
    }

    .sm-img p{
        letter-spacing: 1px;
        width: 100%;
        height: fit-content;
        position: sticky;
        color: rgb(255, 255, 255);
        bottom: 500px;
        
        margin: 0;
        width: 95%;
    }

    .sm-footer h2{
        text-align: center;
        font-size: 24px;
        margin: 5% 5%;
        
    }

    .sm-footer p{
        font-size: 12px;
    }
}