.contact-div{
    text-align: center;
    background-color: black;
    color: whitesmoke;
    font-family: 'Brixton_Wood';
    border-bottom: 1px solid white;
    padding-top: 2%;
    padding-bottom: 10%;
    
}

.contact-header{
    background: url('../images/Header\ banner.jpg');
    font-size: 150px;
    background-size: 100%;
    padding: 2% 0;
}

.contact-body h2{
    padding-bottom: 10%;
    padding-top: 5%;
    font-family: 'Brixton_Wood';
    font-size: 120px;
    
}

.type{
    font-family: 'Arial Narrow';
    font-size: 60px;
    padding: 0;
}

.input{
    font-family: 'Brixton_Wood';
    font-size: 110px;
   height: fit-content;
}

@media (max-width: 1000px) {
    .contact-div{
        min-height: 90vh;
    }

    .contact-body h2{
        font-size: 36px;
    }

    .contact-body p{
        font-size: 28px;
    }
}