body{
    font-family: 'Arial Narrow';
}

.homepage {
    text-align: center;
    background-color: whitesmoke;
}

.rompala-home-logo {
    display: flex;
    position: relative;
    background: url('../Home/background.mp4');
    background-size: cover;
}

.rompala-logo {
    max-width: 100%;
    height: 100%;
}

.image-spot{
    justify-content: center;
    position: relative;
    padding: 10% 10%;
}

.about-home {
    text-transform: capitalize;
    background-color: #000000;
    padding: 2% 20%;
    color: whitesmoke;
    height: fit-content;
    font-size: xx-large;
    
}

.logos {
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: whitesmoke;
    display: flex;
    padding: 1% 20% 1% 20%;
}

.card{
    max-width: 300px;
    max-height: 300px;
    color: rgb(0, 0, 0);
    cursor: pointer;
    transition: 1s ease-in-out;
    transform-style: preserve-3d;

    &:hover {
      transform: rotateY(0.5turn);
    }

    .card__face {
    position: relative;
      backface-visibility: hidden;
      transition: 1s ease-in-out;
      width: 100%;
      height: 100%;
      

      &.card__face--front{
        max-height: 200px;
        display: block;
        position: relative;
        z-index: 5;
      }

      &.card__face--back {
        top: -100px;
        position: relative;
        z-index: 3;
        transform: rotateY(0.5turn);
        background-color: whitesmoke;
        color: #000000;
        font-size: large;
      }
    }
  }


img {
    width: 100%;
    height: 100%;
}

video{
    position: absolute;
    object-fit: cover;
    z-index: 0;
    max-height: 100%;
    width: 100%;
}

.coming-soon{
  color: rgb(255, 255, 255);
  font-size: 48px;
}

a{
  color: #000000;
}

.clickhere{
  color:#000000;
}

@media (max-width: 1000px) {
  .homepage{
    padding-top: 10%;
    min-height: 80vh;
  }

  .about-home{
    font-size: 15px;
    letter-spacing: 1px;
  }

  .logos{
    max-height: 10%;
    height: fit-content;
  }

  .card{
    max-height: 5%;
    max-width: 50%;
  }

  .card__face--back{
    display: none;
  }
}